import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"row gx-0 flex-grow-1\">\n  {{#if this.loadComponents.isRunning}}\n    <Spinner @size=\"md\" />\n  {{else}}\n    {{#if this.notificationVc}}\n      <ToElsewhere\n        @named=\"oes-notifications\"\n        @send={{component\n          \"visualization-component-renderer\"\n          configuredComponent=this.notificationVc\n        }}\n      />\n    {{/if}}\n    <div class=\"col-2 d-flex flex-grow-1\">\n      {{#if this.navVc}}\n        <VisualizationComponentRenderer\n          @configuredComponent={{this.navVc}}\n          @navVc={{true}}\n        />\n      {{/if}}\n    </div>\n    <div class=\"col-10 d-flex flex-grow-1\">\n      {{#if this.activeVc}}\n        <VisualizationComponentRenderer\n          @configuredComponent={{this.activeVc}}\n          @readOnly={{@model.internalView.module.readOnly}}\n        />\n      {{/if}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"row gx-0 flex-grow-1\">\n  {{#if this.loadComponents.isRunning}}\n    <Spinner @size=\"md\" />\n  {{else}}\n    {{#if this.notificationVc}}\n      <ToElsewhere\n        @named=\"oes-notifications\"\n        @send={{component\n          \"visualization-component-renderer\"\n          configuredComponent=this.notificationVc\n        }}\n      />\n    {{/if}}\n    <div class=\"col-2 d-flex flex-grow-1\">\n      {{#if this.navVc}}\n        <VisualizationComponentRenderer\n          @configuredComponent={{this.navVc}}\n          @navVc={{true}}\n        />\n      {{/if}}\n    </div>\n    <div class=\"col-10 d-flex flex-grow-1\">\n      {{#if this.activeVc}}\n        <VisualizationComponentRenderer\n          @configuredComponent={{this.activeVc}}\n          @readOnly={{@model.internalView.module.readOnly}}\n        />\n      {{/if}}\n    </div>\n  {{/if}}\n</div>","moduleName":"client-app-omnivise-web/components/oes-view.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/oes-view.hbs"}});
import Component from '@glimmer/component';
import { type Registry as Services, service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export interface OesViewSignature {
  // The arguments accepted by the component
  Args: {
    model: any;
    avc: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

const NAV_VC_ID = '--oes--oes_oes-nav';
const NOTIFICATION_VC_ID = '--oes--oes_oes-notifications';
const OES = '--oes--oes_oes';

export default class OesView extends Component<OesViewSignature> {
  @service declare urlState: Services['url-state'];
  @service declare store: Services['store'];

  components = [];
  @tracked navVc = null;
  @tracked notificationVc = null;
  @tracked _activeVc = null;

  constructor(owner: unknown, args: OesViewSignature['Args']) {
    super(owner, args);
    this.loadComponents.perform();
  }

  @task
  *loadComponents() {
    const internalView = yield this.store.findRecord(
      'internal-view',
      this.args.model.internalView.id,
      {
        include: [
          'module',
          'configuredWidgets.configuredComponents.componentDescriptor',
        ].join(','),
      },
    );

    const widgets = yield internalView.configuredWidgets;

    for (const widget of widgets) {
      const ccs = yield widget.configuredComponents;

      this.navVc = ccs.find((cc) => cc.id === NAV_VC_ID);
      this.notificationVc = ccs.find((cc) => cc.id === NOTIFICATION_VC_ID);
      // this._activeVc = ccs.find((cc) => cc.id === this.args.avc);

      const ccsOnRightSide = ccs.filter(
        (cc) => cc.id !== NAV_VC_ID && cc.id !== NOTIFICATION_VC_ID,
      );
      this.components.push(...ccsOnRightSide);
    }
  }

  get normalizedAvc() {
    return `--oes--oes_${this.args.avc}`;
  }

  get activeVc() {
    // if (this._activeVc?.id === this.args.avc) {
    //   return this._activeVc;
    // }
    // this._activeVc = this.components.find((cc) => cc.id === this.args.avc);
    // return this._activeVc;
    return (
      this.components.find((cc) => cc.id == this.normalizedAvc) ??
      this.components.find((cc) => cc.id == OES)
    );
  }
}
